/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { countryCodes } from '../translations/countryCodes';
import { getBrandProcessCash, getDefaultProcessAlias } from '../transactions/transactionHelpers';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   key: 'category_gender',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     {
  //       label: 'men',
  //       option: 'men',
  //     },
  //     {
  //       label: 'women',
  //       option: 'women',
  //     },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     group: 'primary',
  //     label: 'Category',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //   },
  // },
  {
    key: 'brandChild',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { label: '3Jolie', option: '3jolie' },
      { label: '3Juin', option: '3juin' },
      { label: 'Alysi', option: 'alysi' },
      { label: 'Aniye By', option: 'aniye by' },
      { label: 'Eddy Daniele', option: 'eddy daniele' },
      { label: 'Jucca', option: 'jucca' },
      { label: 'Leonie Hanne x Liu Jo', option: 'leonie hanne x liu jo' },
      { label: 'So Allure', option: 'so allure' },
      { label: 'Via Roma 15', option: 'via roma 15' },
      { label: 'Vic Matié', option: 'vic matié' },
      { label: '8PM', option: '8pm' },
      { label: 'Absolute Cashmere.', option: 'absolute cashmere' },
      { label: 'Alberto Luti', option: 'alberto luti' },
      { label: 'Alessia Santi', option: 'alessia santi' },
      { label: "Aleva' Bijoux", option: "aleva' bijoux" },
      { label: 'Alexander Smith', option: 'alexander smith' },
      { label: 'Amaranto', option: 'amaranto' },
      { label: 'Angel Alarcon', option: 'angel alarcon' },
      { label: 'Anna F', option: 'anna f' },
      { label: 'Ash', option: 'ash' },
      { label: 'Autry', option: 'autry' },
      { label: 'Bazar Deluxe', option: 'bazar deluxe' },
      { label: 'Betta Corradi', option: 'betta corradi' },
      { label: 'Bibi Lou', option: 'bibi lou' },
      { label: 'Birkenstock', option: 'birkenstock' },
      { label: "Borgo dell'Ortica", option: "borgo dell'ortica" },
      { label: 'Bruno Carlo', option: 'bruno carlo' },
      { label: 'Campomaggi', option: 'campomaggi' },
      { label: 'Chie Mihara', option: 'chie mihara' },
      { label: 'Christina Paris', option: 'christina paris' },
      { label: 'Circolo 1901', option: 'circolo 1901' },
      { label: 'Comme Toi', option: 'comme toi' },
      { label: 'Crocs', option: 'crocs' },
      { label: 'Curiositè', option: 'curiositè' },
      { label: 'Cycle', option: 'cycle' },
      { label: 'Daniele Fiesoli Collezione_01', option: 'daniele fiesoli collezione_01' },
      { label: 'Date', option: 'date' },
      { label: 'Department 5', option: 'department 5' },
      { label: 'Don The Fuller', option: 'don the fuller' },
      { label: 'Dondup', option: 'dondup' },
      { label: 'Duno', option: 'duno' },
      { label: 'Elena Iachi', option: 'elena iachi' },
      { label: 'Emu', option: 'emu' },
      { label: 'Fiorentini + Baker', option: 'fiorentini + baker' },
      { label: 'Flower Mountain', option: 'flower mountain' },
      { label: 'Fruit', option: 'fruit' },
      { label: 'Grifoni', option: 'grifoni' },
      { label: 'Haikure', option: 'haikure' },
      { label: 'Hinnominate', option: 'hinnominate' },
      { label: 'I Love My Pants', option: 'i love my pants' },
      { label: 'Icon Denim', option: 'icon denim' },
      { label: 'Joia Paris', option: 'joia paris' },
      { label: 'Keep Originals', option: 'keep originals' },
      { label: 'La Femme Blanche', option: 'la femme blanche' },
      { label: 'Le Bohémien', option: 'le bohémien' },
      { label: 'Lenora', option: 'lenora' },
      { label: 'Liviana Conti', option: 'liviana conti' },
      { label: 'Maliparmi', option: 'maliparmi' },
      { label: 'Mama B', option: 'mama b' },
      { label: 'MC2 Saint Barth', option: 'mc2 saint barth' },
      { label: 'Merci', option: 'merci' },
      { label: 'Mo5ch1no Jeans - Moschino', option: 'mo5ch1no jeans - moschino' },
      { label: 'Moon Boot', option: 'moon boot' },
      { label: 'Mou', option: 'mou' },
      { label: 'My Best Bags', option: 'my best bags' },
      { label: 'Myskin', option: 'myskin' },
      { label: 'Même Road', option: 'même road' },
      { label: 'Neirami', option: 'neirami' },
      { label: 'Nina Tra Le Nuvole', option: 'nina tra le nuvole' },
      { label: 'Niu', option: 'niu' },
      { label: 'Off Wear', option: 'off wear' },
      { label: 'Optatum', option: 'optatum' },
      { label: "Ottod'Ame", option: "ottod'ame" },
      { label: 'Paloma Barcelò', option: 'paloma barcelò' },
      { label: 'People of Shibuya', option: 'people of shibuya' },
      { label: 'Phisique du Role', option: 'phisique du role' },
      { label: 'Pierantonio Gaspari', option: 'pierantonio gaspari' },
      { label: "Pomme d'Or", option: "pomme d'or" },
      { label: 'Rains', option: 'rains' },
      { label: 'Roberto Collina', option: 'roberto collina' },
      { label: 'Roberto Festa', option: 'roberto festa' },
      { label: 'Roy Rogers', option: 'roy rogers' },
      { label: 'RRD Roberto Ricci Design', option: 'rrd roberto ricci design' },
      { label: 'Rue Madam', option: 'rue madam' },
      { label: 'Salvatore Santoro', option: 'salvatore santoro' },
      { label: 'Sebago', option: 'sebago' },
      { label: "Seboy's", option: "seboy's" },
      { label: 'Semi Couture', option: 'semi couture' },
      { label: 'Sep', option: 'sep' },
      { label: 'Sergio Levantesi', option: 'sergio levantesi' },
      { label: 'Solotre', option: 'solotre' },
      { label: 'Souvenir', option: 'souvenir' },
      { label: 'Strategia', option: 'strategia' },
      { label: 'Suoli', option: 'suoli' },
      { label: 'Superga', option: 'superga' },
      { label: 'Tannerie', option: 'tannerie' },
      { label: 'Tela', option: 'tela' },
      { label: 'Veja', option: 'veja' },
      { label: 'Voile Blanche', option: 'voile blanche' },
      { label: 'Wushu Ruyi', option: 'wushu ruyi' },
      { label: 'Zoe', option: 'zoe' },
    ],
    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Brand',
    },
    showConfig: {
      label: 'Brand',
    },
    saveConfig: {
      label: 'Brand',
    },
  },
  {
    key: 'type',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { label: 'Abiti', option: 'abiti' },
      { label: 'Camicie / Bluse', option: 'camicie / bluse' },
      { label: 'Cappotti / Soprabiti', option: 'cappotti / soprabiti' },
      { label: 'Gonne', option: 'gonne' },
      { label: 'Pantaloni', option: 'pantaloni' },
      { label: 'Sandali', option: 'sandali' },
      { label: 'Shorts', option: 'shorts' },
      { label: 'Stivaletti / Tronchetti', option: 'stivaletti / tronchetti' },
      { label: 'T-shirt', option: 't-shirt' },
      { label: 'Top', option: 'top' },
      { label: 'Anelli', option: 'anelli' },
      { label: 'Borse grandi', option: 'borse grandi' },
      { label: 'Borse medie', option: 'borse medie' },
      { label: 'Borse piccole', option: 'borse piccole' },
      { label: 'Bracciali', option: 'bracciali' },
      { label: 'Calze', option: 'calze' },
      { label: 'Capelli', option: 'capelli' },
      { label: 'Cappelli / Berretti', option: 'cappelli / berretti' },
      { label: 'Cinture', option: 'cinture' },
      { label: 'Collane', option: 'collane' },
      { label: 'Cravatte', option: 'cravatte' },
      { label: 'Decollete`', option: 'decollete' },
      { label: 'Gift Cards', option: 'gift cards' },
      { label: 'Gilet', option: 'gilet' },
      { label: 'Giubbotti', option: 'giubbotti' },
      { label: 'Giubbotti Pelle', option: 'giubbotti pelle' },
      { label: 'Guanti', option: 'guanti' },
      { label: 'Jeans', option: 'jeans' },
      { label: 'Leggins', option: 'leggins' },
      { label: 'Maglie / Cardigan', option: 'maglie / cardigan' },
      { label: 'Orecchini', option: 'orecchini' },
      { label: 'Portacellulare', option: 'portacellulare' },
      { label: 'Sabot', option: 'sabot' },
      { label: 'Scarpe basse', option: 'scarpe basse' },
      { label: 'Scarpe con tacco', option: 'scarpe con tacco' },
      { label: 'Sciarpe', option: 'sciarpe' },
      { label: 'Sneakers', option: 'sneakers' },
      { label: 'Tailleur', option: 'tailleur' },
      { label: 'Tute', option: 'tute' },
      { label: 'Zaini', option: 'zaini' },
    ],
    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Type',
    },
    showConfig: {
      label: 'Type',
    },
    saveConfig: {
      label: 'Type',
    },
  },
  {
    key: 'size',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { label: '36', option: '36' },
      { label: '37', option: '37' },
      { label: '38', option: '38' },
      { label: '40', option: '40' },
      { label: '42', option: '42' },
      { label: '44', option: '44' },
      { label: 'L', option: 'l' },
      { label: 'M', option: 'm' },
      { label: 'S', option: 's' },
      { label: 'U', option: 'u' },
      { label: '0', option: '0' },
      { label: '23', option: '23' },
      { label: '24', option: '24' },
      { label: '25', option: '25' },
      { label: '26', option: '26' },
      { label: '27', option: '27' },
      { label: '34', option: '34' },
      { label: '35', option: '35' },
      { label: '35/36', option: '35/36' },
      { label: '35/38', option: '35/38' },
      { label: '36½', option: '36½' },
      { label: '36/37', option: '36/37' },
      { label: '36/38', option: '36/38' },
      { label: '37½', option: '37½' },
      { label: '38½', option: '38½' },
      { label: '38/40', option: '38/40' },
      { label: '39', option: '39' },
      { label: '41', option: '41' },
      { label: '57', option: '57' },
      { label: '7', option: '7' },
      { label: '7,5', option: '7,5' },
      { label: '70', option: '70' },
      { label: '75', option: '75' },
      { label: '80', option: '80' },
      { label: 'I', option: 'i' },
      { label: 'II', option: 'ii' },
      { label: 'S-M', option: 's-m' },
      { label: 'XL', option: 'xl' },
      { label: 'XS', option: 'xs' },
      { label: 'XXS', option: 'xxs' },
    ],
    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Size',
    },
    showConfig: {
      label: 'Size',
    },
    saveConfig: {
      isRequired: true,
      label: 'Size',
    },
  },
  {
    key: 'condition',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'New with tags',
        option: 'new_with_tags',
      },
      {
        label: 'Like new',
        option: 'like_new',
      },
      {
        label: 'Great',
        option: 'great',
      },
      {
        label: 'Good',
        option: 'good',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Condition',
    },
    showConfig: {
      label: 'Condition',
    },
    saveConfig: {
      isRequired: true,
      label: 'Condition',
    },
  },
  // {
  //   key: 'country',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: countryCodes.map(countryCode => ({
  //     label: countryCode.en,
  //     option: countryCode.en,
  //   })),
  //   filterConfig: {
  //     indexForSearch: true,
  //     group: 'primary',
  //     label: 'Country',
  //   },
  //   showConfig: {
  //     label: 'Country',
  //   },
  //   saveConfig: {
  //     label: 'Country',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */
const cashBrandTransactionProcess = getBrandProcessCash();
const alias = getDefaultProcessAlias();

export const listingTypes = [
  {
    listingType: 'product',
    label: 'Purchase cash',
    transactionType: {
      process: cashBrandTransactionProcess,
      alias: `${cashBrandTransactionProcess}/${alias}`,
      unitType: 'item',
    },
    stockType: 'oneItem',
    defaultListingFields: {
      price: true,
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
