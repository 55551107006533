export const conditionOptions = [
  { value: 'new_with_tags', label: 'New with tags' },
  { value: 'like_new', label: 'Like new' },
  { value: 'great', label: 'Great' },
  { value: 'good', label: 'Good' },
];

export const countryOptions = [{ value: 'Italy', label: 'Italy' }];

export const countryCodeMap = {
  Italy: 'IT',
};
