/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
import { offersFeature } from '../../../config/configOffers';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  MultiLanguageSelect,
  SettingsPopup,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          {/* <MultiLanguageSelect /> */}
          <SettingsPopup currentPage={currentPage} />

          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('LandingPage'))}
            name="LandingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.home" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
            name="SearchPage"
          >
            <FormattedMessage id="TopbarMobileMenu.shop" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SellHomePage'))}
            name="SellHomePage"
          >
            <FormattedMessage id="TopbarMobileMenu.sell" />
          </NamedLink>
          <a href="https://www.commetoi.it/" className={css.navigationLink}>
            <span>
              <FormattedMessage id="TopbarMobile.brandLink" />
            </span>
          </a>
        </div>
        <div className={css.footer}>
          <NamedLink name="SellHomePage" className={css.createNewListingLink}>
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;

  return (
    <div className={css.root}>
      {/* <MultiLanguageSelect /> */}
      <SettingsPopup currentPage={currentPage} />

      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('LandingPage'))}
          name="LandingPage"
        >
          <FormattedMessage id="TopbarMobileMenu.home" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
          name="SearchPage"
        >
          <FormattedMessage id="TopbarMobileMenu.shop" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SellHomePage'))}
          name="SellHomePage"
        >
          <FormattedMessage id="TopbarMobileMenu.sell" />
        </NamedLink>
        <a href="https://www.commetoi.it/" className={css.navigationLink}>
          <span>
            <FormattedMessage id="TopbarMobile.brandLink" />
          </span>
        </a>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {offersFeature && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('OffersPage'))}
            name="OffersPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourOffersLink" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink name="SellHomePage" className={css.createNewListingLink}>
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
